@import '../../styles/variable';
@import '../../styles//mixin';
.gallery{
    width: 100%;

    &-container{
        padding: 20px;
        width: 100%;
        &-header{
            &-title{
                font-size: 32px;
                font-weight: 700;
                margin-bottom: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                span{
                    display: inline-block;
                    width: 44px;
                    height: 3.3px;
                    background: $yellow;
                }
            }
            &-text{
                font-size: 15px;
                font-weight: 400;
                color: $primary;
            }
        }
    
            .image_container{
                display: flex;
                align-items: center;
                justify-content: center;
                // background: #000;
                width: 100%;
               img{
                   width: 100%;
                   height: 100%;
               }
                &:hover{
                    transform: scale(1.05);
                }
                
            }
            @include mobile{
                flex-direction: column;
                width: 100%;
                align-items: center;
                // height: 467px;
                // &:nth-child(2){
                //     display: none;
                // }
                img{
                    width: 200px; 
                   height: 100%;
                }
            }
    
    }
}

img{
    border-radius: 5px;
}

