@import "../../styles/variable";
@import "../../styles/mixin";

.footer{
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary;
    color: white;
    padding: 30px 0;
    &-container{
        
        max-width: 1200px;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 20px;
        
        &-info{
            // padding: 20px;
            max-width: 350px;
            width: 100%;
            margin-bottom: 50px;
            // background: #000;
            @include mobile{
                text-align: center;
                h2{
                    font-size: 27px !important;
                    // background: red;
                    .icon{
                        font-size: 20px !important;
                        margin-left: 5px !important;
                        width: 40px ;
                       
                    }
                }
                p,a{
                    font-size: 13px;
                }
            }
            h2{
                font-size: 2.6rem;
                letter-spacing: 2px;
                margin-bottom: 20px;
                text-align-last: left;
                // background: #000;
                .icon{
                    font-size: 2rem;
                    margin-left: 20px;
                    width: 50px;
                    @include tablet{
                        width: 20px;
                        margin-left: 0;
                    }
                    
                }
            }
            p{
                margin-bottom: 10px;
                text-align-last: left;
                
                a{
                    &:hover{
                        color: $yellow;
                    }
                }
            }
        }
        &-navbar{
            display: flex;
            justify-content: space-between;
            // padding: 20px;
            width: 100%;
            border-bottom: 1px solid #fff;
            margin: 30px 0;
            @include mobile{
                display: block;
            }
            ul{
                display: flex;
                padding-bottom: 20px;
                
                @include mobile{
                    display: block;
                    li{
                        padding-right: 0 !important;
        
                        a{
                            font-size: 2rem;
                            font-weight: 300;
                            padding: 20px;
                            width: 100%;
                            margin: 0;
                            display: block;
                            text-align: center;
                            &:hover{
                                background: $yellow;
                                border-radius: 5px;
                                color: $primary !important;
                                font-weight: 500;
                                font-size: 18px;
                                letter-spacing: 3px;
                            }
                        }
                    }
                }
                li{
                    padding-right: 20px;
                    a{
                        &:hover{
                            color: $yellow;
                        }
                    }
                }
            }
            
        }
        &-icons{
            a{
                padding-left: 20px;
                font-size: 18px;
            }
            @include mobile{
                display: flex;
                justify-content: space-evenly;
                // background: #000;
                padding: 20px 0;
                a{
                    svg{
                        font-size: 20px;
                    }
                }
            }
        }
        &-text{
            font-size: 12px;
            text-align: right;
            letter-spacing: 2px;
            
            
            svg{
                font-size: 13px;
                margin-left: 10px;
            }
        }
    }
}