@import "../../styles/mixin";
@import "../../styles/variable";
.card{
    
    width: 250px;
    height: 259px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    margin: 20px;
    color: $primary;
    

    &:active{
        border: 1px solid blue;
        box-shadow: 1px 1px 5px black;
        border-radius: 3px;
    }
    &:hover{
        border: 1px solid blue;
        box-shadow: 1px 1px 5px black;
        border-radius: 3px;
    }
    &-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        position: relative;
        transition: all .51s ease;
        img{
            margin: 10px;
            width: 40px;
        }
        h2{
            font-weight: 600px;
            font-size: 1.5em;
            margin: 3px;
        }
        &-icon{
            background: rgb(255, 255, 255);
            z-index: 3;
            width: 100%;
        }
        &-info{
            padding: 10px 10px;
            font-size: 14px;
            color: #555;
            // opacity: 0;
            // position: absolute;
            letter-spacing: 0.02em;
            width: 100%;
            transition: all .51s ease;
            // z-index: -1;
            line-height: 18px;
            text-transform: lowercase;
        }
    }
    &:hover{
        .card-container-info{
            opacity: 1;
            position: relative;
            z-index: 1;
        }
        
    }
    
}