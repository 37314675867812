@import "../../styles/variable";
@import "../../styles/mixin";
.activity{
   
    padding: 20px;
    &span{
        display: block;
        
    }
    @include mobile{
        padding: 0;
    }
    &-container{
        display: flex; 
        background: $primary;
        justify-content: center;
        align-items: center;
    
        
        @include mobile{
            flex-direction: column-reverse;
            width: 100%;
        }
        &-img{
            flex: 1;
            width: 59%;
            height: 100%;
            @include mobile{
                width: 100%;
            }
        }
        .info-container{
            flex: 1;
            position: relative;
            // background: #000;
           
         
  
            img{
                width: 70%;
                height: 100%;
                color: #555;
                @include mobile{
                    padding: 25px;
                }
                
            }
            .lead-wrapper{
                // background: #000;
                position: absolute;
                bottom: 0;
                right: 0;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                margin: auto;
                display: flex;
            }
            .lead{
                
                // top: 7%;
                // left: 0;
                color: white;
                // background: #00f;
                margin: auto;
                display: flex;
                // height: 100%;
                flex-direction: column;
                // align-items: ;
                text-align: left;
                justify-content: center;
                // align-items: center;
                margin: 0 10px 0 0;
                font-size:100%;
                line-height: 20px;
                // padding: 20px 0;
                // margin: 20px;
                // margin: auto;
                
                @include mobile{
                    line-height: 17px;
                    font-size: 12px;
                    color: #ddd;
                    background: rgba(0, 0, 0, 0.05);
                    border-radius: 5px;
                    padding: 10px;
                }
                span{
                    background: $yellow;
                    width: 80%;
                    height: 3px;
                    margin: 10px;
                }
                // &::before{
                //     content: "";
                //     top: 20px;
                //     width: 50%;
                //     position: absolute;
                //     background: $yellow;
                //     height: 2px;
                //     // border-top: 2px solid $yellow;
                //     // border-bottom: 2px solid $yellow;
                // }
                // &::after{
                //     content: "";
                //     bottom: 20px;
                //     width: 60%;
                //     position: absolute;
                //     background: $yellow;
                //     height: 2px;
                //     // border-top: 2px solid $yellow;
                //     // border-bottom: 2px solid $yellow;
                // }
            }
        }
    }
}