@import "../.../../../styles/mixin";



.solution{
    // height: 1080px;
    margin: 30px 0;
}



.solution-wrapper{
    // margin: auto;
    // background: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 4rem;
}



.solution-list{
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: auto;
    @include mobile{
        flex-direction: column;
        width: 100%;
    }
}


.solution-listitem{
    width: 50%;
    text-align: left;
    display: flex;
    margin: 30px 0;
    @include mobile{
        width: 100%;
        
    }

}

@include mobile{
    .info1{
        font-size: 16px;
    }
}
@include mobile{
    .info2{
        font-size: 16px;
    }
}
@include mobile{
    .info3{
        font-size: 16px;
    }
}
@include mobile{
    .info4{
        font-size: 16px;
    }
}
@include mobile{
    .info5{
        font-size: 16px;
    }
}
@include mobile{
    .info6{
        font-size: 16px;
    }
}
@include mobile{
    .info7{
        font-size: 16px;
    }
}

.solution-item-number{
    font-size: 20px;
    color: hsl(240, 55%, 48%);
    margin-right: 5px;
    @include mobile{
        font-size: 30px;
    }
}




.image-wrapper{
    position: relative;
    display: flex;
    width: 80%;
    // background: #000;
    margin: auto;
    margin-top: 40px;
    // @include mobile{
    //     flex-direction: column;
    //     width: 40%;
    // }
}

.rectangle{
    content: '';
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: -20px;
    width: 250px;
    border: 4px solid yellow;
    margin-left: 13rem;
    z-index: -100;
    @include mobile{
        display: none;
    }
}

.rectangle1{
    content: '';
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    bottom: 30px;
    width: 200px;
    border: 4px solid yellow;
    z-index: -100;
    margin-left: 16rem;
    @include mobile{
        display: none;
    }
}


.image-box{
    width: 500px;
    &:nth-child(2){
        position: absolute;
        right: 75px;
        top: -70px;
        @include mobile{
            display: none;
        
        }
    }

   
}

.image1{
    width: 100%;
    margin-left: 15rem;
    @include mobile{
        display: flex;
        margin: auto;
        width: 100%;
        
    }
}

.image2{
    width: 70%; 
}



.button{

    // position: relative;
    bottom:350px;
    // transform: translateX(20%);
    display: flex;
    flex-direction: row;
    column-gap: 90px;
    background: #fff;
    margin-top: 50px;
    @include mobile{
        bottom:-190px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        align-items: center;
        justify-content: center;
    }
}

.button1{
    width:150px;
    height:40px;
    color:white;
    background-color: #ffd700;
    border-color: transparent;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}
.button1:hover{
    background-color: white;
    color:#ffd700;
    border-color: #ffd700;
    border-style: solid;
}


.button2{
    width:150px;
    height:40px;
    color:#ffd700;
    background-color: transparent;
    border-style: solid;
    border-color: #ffd700;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    font-size: 16px;
}
.button2:hover{
    background-color: #ffd700;
    color:white;
    border-style: solid;
}
