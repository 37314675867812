@import "../../styles/variable";
.home{
    z-index: 2;
    
    header{
        position: relative;
        .overlay2{
            background-image:url(../../assets/herobg.jpg);
            background-position: center;
            background-size: cover;
    
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;right: 0;
            z-index: -1;
            &::after{
                content: "";
                background-color:$transparentBG ;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;right: 0;
                z-index: -1;
            }
       }
    }
}