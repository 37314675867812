// @import url('https://fonts.googleapis.com/css2?family=Anek+Gurmukhi:wght@400,500&display=swap');
 @import "./global";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  *{
      transition: all 0.5s ease-in-out;
      letter-spacing: 1px;
  }
  img{
    width: 100%;
  }
  html{
    scroll-behavior:smooth;
  }