@import "../../styles/variable";
@import "../../styles/mixin";
.services{

    position: relative;
    padding:7px 0  100px 0;
    @include mobile {
        padding: 0 0 0 0;
    }
    &-bg{
        position: absolute;
        background: url(../../assets/robots.jpg) center;
        background-size: cover;
        height: 70vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -2;
        @include mobile{
            min-height: 20vh;
            height: 100%;
        }
        &::after{
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(253, 253, 253, 0.3);
            z-index: -1;
        }
    }
    &-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .header{
            position: relative;
            width: 100%;
            // background: #000;
            @media screen and (min-width:1000px) {
                min-height: 140px;
                height: 100%;
                padding-top: 20px;
                
            }
        }
        .title{
        //    font-size: 8rem;
        //    background: #000;
            top: -20px;
            width: 100%;
            text-transform: lowercase !important;
            letter-spacing: 30px;
            @media screen and (min-width:1000px) {
                top: -93px;
                height: 20vh; 
                position: absolute; 
                font-size: 1266%;
            }
            @media screen and (min-width:958px) {
                top: -50px;
            }
            @include tablet{
                font-size: 60px;
                letter-spacing: 20px;
            }
            @include mobile{
                font-size: 44px;
                letter-spacing: 15px;
            }
            text-transform: uppercase;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-width: 2px;
            -webkit-text-stroke-color: rgba(5, 95, 197, .21);
        }
        // .sub-title{
        //     font-size: 3rem;
        // }
        .cards{
            margin: 30px;
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            height: 100%;
            justify-content: space-evenly;
            max-width: 1200px;
            
            @include mobile{
                display: none;
            }

        }
        .links{
            display: flex;
            padding: 20px;
            .navlink{
                padding:  7px 20px;
                font-size: 16px;
                border-radius: 5px;
                &:active{
                    background: $yellow;
                }
            }
            @media screen and (min-width:500px) {
                display: none;
            }
        }
    }

    .activities{

        width: 100%;
        max-width: 1290px;
        transition: div 5s ;
    }

}
