@import "./mixin";
@import "./variable";
// reset 
htm, *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 16px;
    letter-spacing: 1.5px;
    font-family: $mainFont;
    @include tablet{
        font-size: 9px;
    }
    @include tablet{
        font-size: 7px;
    }
}
body{
    
    padding: 0;
    margin: 0;
}
ul,li{
    margin: 0;
    padding: 0; 
    list-style: none;
}
a{
    text-decoration: none;
    color: inherit;
}
.testimonial,
.services,
.project,
.about{
    letter-spacing: 00.02em;
}