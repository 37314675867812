@import "../../styles/variable";
@import "../../styles/mixin";
.container{
  display: flex;
  // align-items: center;
  justify-content: center;
  // margin: auto;
  width: 100%;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  font-family: $mainFont;
  display: flex;
  // overflow: hidden;
  box-sizing: border-box;
  @include mobile{
    padding: 10px;
    flex-direction: column;
  }
  @include tablet{
    padding: 10px;
    flex-direction: column;
  }
  .info{
    // flex: 1;
    margin: 70px 30px 0 0;
   text-align: left;
    width: 456px;
    // border: 1px solid yellow;

    @include mobile{
      width: 100%;
    }
    &-title{
      font-size: 55px;
      font-weight: 700;
      margin-bottom: 30px;
      @include mobile{
        font-size: 39px;
        font-weight: 500;
        span{
          font-size: 39px !important;
          
        }
      }
      span{
        font-size: 55px;
        color: $primary;
        
      }
    }
    &-text{
      padding: 28px 0;
      @include mobile{
        font-size: 16px;
      }
    }
    &-icons{
      // background: #000;

      text-align-last: left;
      &-icon{
        margin-right: 20px;
      }
      @include mobile{
        // font-size: 18px;
        display: none;
      }
    }
  }
  .form{
    width: 634px;
    background: rgba(0, 0, 0,0.1);
    flex: 1;
    border-radius: 5px;
    padding: 30px;
    padding-bottom: 70px;
    max-width: 700px;
    
    @include mobile{
      padding: 0px;
      background: rgb(245, 237, 237);
      margin: 20px 0;
      width: 100%;
    }
    @include tablet{
   
      margin: 20px 0;
      width: 100%;
    }
    &-wrapper-label{
      text-align: left;
      // background: #000;
      margin-bottom: 10px;
      @include mobile{
        padding: 10px 0 0 5px;
        font-size: 15px;
      }
    }
    &-wrapper{
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      @include mobile{
        margin: 0;
      }
      &-control{
        display: block;
        padding: 12px; 
        
        border-radius: 4px; 
        box-sizing: border-box; 
        margin-top: 6px; 
        margin-bottom: 16px;
        resize: vertical ;
        border: none;
        outline: none;
        &::placeholder{
          color: #ccc;
        }

        &:focus{
          border: 1px solid $primary; 
        }
        @include mobile{
          font-size: 15px;
          color: #333;
          padding: 15px;
          margin: 0;
        }
      }
      &-btn{
        background-color: $yellow;
        color: white;
        padding: 12px 20px;
        border: none;
        border-radius: 4px;
        width: 200px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        font-family: $mainFont;
        @include mobile {
          font-size: 15px;
          width: 100%;
          &-icon{
            font-size: 20px;
          }
        }
        &-icon{
          margin-left: 10px;
        }
        &:hover {
          background-color: $yellow;
        }
      }
    }
  }
}

 
 