@import "../../styles/variable";
@import "../../styles/mixin";
.project{
    min-height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 100px 20px;
    .bg-overlay{
        background: url(../../assets/c.jpg) center;
        background-size: cover;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;z-index: -2;
        &::after{
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $transparentBG;
            z-index: -1;
        }
    }.title{
        font-size: 3.9rem;
        color: white;
        font-weight: 500;
        svg{
            color: $yellow;
            font-size: 18px;
        }
    }
    &-container{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 1200px;
        position: relative;
        padding: 30px 0;
    }
    .video-container{
        
        box-shadow: 1px 1px 20px 4px rgba(0, 0, 0,0.3);
        width: 40%;
        margin: 20px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        .poster{
            width: 100%;
        }
        .video-overlay{
            position: absolute;
            // top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 0;
            bottom: 0;
            background: rgba(0, 0, 0,0.7);
            .project-info{
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: 30%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                // background: #000;
                p{
                    opacity: 0;
                    background: $yellow;
                    width: 100%;
                    height: 50%;
                }
            }
            
            .video-button{
                width: 50px;
                opacity: 0;
            }
        } 
        // transition: opacity 30 ease-in;
        &:hover{
            .video-overlay{
                bottom: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                
                .video-button{
                    // margin: auto;
                    opacity: 1;
                }
                p{
                    opacity: 1;
                    color: #fff;
                    font-size: 24px;
                    padding: 20px;
                    @include mobile{
                        font-size: 15px;

                    }
                }

            }

        }
        &.openMedia{
            width: 80%;
            // background: #000;
            max-height: 500px;
            
            @include mobile{
                width: 100%;
                height: 200px;
            }
        }
        .iwrap{
             position: absolute;
                top: -20px;
                left: 0;      
                z-index: 4;
                width: 100%;
                height: 100%;
               
                iframe{
                    width: 100%;
                    height: 100%;
                   border-radius: 5px;
                }
                .close{
                    color: white;
                    font-size: 18px;
                    z-index: 10;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:hover{
                        background: rgb(224, 32, 32);
                    }
                }
        }
        @include mobile{
            width: 100%;
            height: 100%;
            position: relative;
           
        }
    }

}

.poster,.video-overlay, .video-container{
    border-radius: 5px;
}