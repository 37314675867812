$mobile-width: 480px;
$tablet-width: 960px;
$desktop-width: 1260px;

@mixin mobile {
    @media (max-width: #{$mobile-width}){
        @content
    }
}
@mixin tablet {
    @media (max-width: #{$tablet-width}){
        @content
    }
}
@mixin desktop {
    @media (max-width: #{$desktop-width}){
        @content
    }
}