@import "../../styles/variable";
@import "../../styles/mixin";
.testimonial{

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
 
    &-container{
        padding: 20px;
        @include mobile{
            padding: 0;
        }
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        align-items: center;justify-content: center;
        .title-info{
            padding: 20px;
            .title{
                font-size: 2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                svg{
                    font-size: 2rem;
                    margin-top: .71rem;
                    color: $primary;
                }
            }
            .text-info{
                text-align: center;
                color: $primary;
                font-size: 14px;
            }
        }
       
    } &-card-container{
           
            padding: 0;
            width: 100%;
            // @include mobile{
            //     display: none;
            // }
        }
    .link{
        padding: 20px;
display: flex;
align-items: center;
        font-size: 18px;
        svg{
            background: $yellow;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            font-size:12px;
            padding: 4%;
            color: white;
            margin-right: 10px;
            font-size: 18px;
        }
    }
}