@import "../../styles/variable";
@import "../../styles/global";
@import "../../styles/mixin";
.hero{
    height:calc(100vh - 90px) ;
    position: relative;
    font-size: 0px;
   
    &-container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        .title{
            font-family: $mainFont;
            color: white;
            font-size:10rem;
            text-transform: uppercase;
            margin-bottom: 20px;
           
            span{
                color: $blue-tx;
                font-size: inherit;
            }
            @include mobile{
                font-size: 7.55rem;
                
            }
            
        }
        .sub-title{
            color: white;
            font-size: 1.79rem;
            font-weight: lighter;
            font-family: $mainFont;
            text-transform: lowercase;
            font-style: italic;
            &::first-letter{
                text-transform: uppercase;
            }
            @include mobile{
                font-size: 2rem;
                font-weight: 500 !important;
            }
           
        }
        &-cta{
  
            display: flex;
            align-items: center;
            flex-direction: column;
           
            margin-top: 40px;

            .desc{
                color: white;
                font-size: 1.15rem;
                padding: 10px;
                font-family: $mainFont;
                border: 0.78px solid $yellow;
                font-weight: 400;
                line-height: 32px;
                text-transform: uppercase;
                @include mobile{
                    font-size: 1.2rem;
                }
                @include tablet{
                    padding: 5px 10px;
                    letter-spacing: 2px;
                }
            }
            .media{
                width: 20%;
                cursor: pointer;
                margin: 20px 0;
            }
            .btn{
                background-color:$yellow;
                color: white;
                display: flex;
                align-items: center;
                // width: 150px;
                width: 220px;
                height: 48px;
                justify-content: center;
                padding: 7px 10px;
                border: none;
                border-radius: 5px;
                font-size: 1rem;
                cursor: pointer;
                
                .icon{
                    margin-left: 10px;
                }
                @include mobile{
                    width: 150px;
                    height: 50px;
                    padding: 5px 7px;
                }
                @include tablet{
                    font-size: 1.7em;
                    letter-spacing: 2px;
                    .icon{
                        font-size: 1.3em;
                    }
                }
            }
        }
    }
    .mouse{
        position: absolute;
        left: 30px;
        cursor: pointer;
        width: 24px;
    bottom: 100px;
    }
}